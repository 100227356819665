import React, { useState , useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Paper } from '@material-ui/core';
import Geosuggest from 'react-geosuggest';
import GoogleMapReact from 'google-map-react';
import Picker from 'assets/images/ui/location-picker.png';
import './geo-location-styles.css';
import styles from './geo-location-styles';

const useStyles = makeStyles(styles);

const MarkerComponent = (props) => {
  const classes = useStyles();
  const { markerName, markerPhoto } = props;

  const name = Object.is(markerName, undefined) ? 'Your Business' : markerName;
  const photo = Object.is(markerPhoto, undefined) ? '': markerPhoto;

  return (
    <div className={classes.marker}>
      <div className={classes.businessName}>
          <img className={classes.icon} src={photo}></img>
          <Typography variant="h6" align="center" className={classes.text}>{name}</Typography>
      </div>
      <img className={classes.picker} src={Picker}></img>
    </div>
  );
}

export default function (props) {
  const classes = useStyles();
  const { title, subtitle, location, onLocationChange, autoFocus, markerPhoto, markerName, errorMessage } = props;
  
  const onSuggestionSelected = (suggestion) => {
    if (suggestion) {
      
      if (suggestion.location.lat && suggestion.location.lng && suggestion.description) {
        
        let countryCode = null;
        if (suggestion.gmaps) {
          let addressComponents = suggestion.gmaps.address_components;
          for (let comp of addressComponents) {
            if (comp.types.includes('country')) {
              countryCode = comp.short_name;
              break;
            }
          }
        }

        onLocationChange({ latitude: suggestion.location.lat, longitude: suggestion.location.lng, address: suggestion.description, countryCode: countryCode});
      } else {
        onLocationChange({ latitude: null, longitude: null, address: null, countryCode: null});
      }
    } else {
      onLocationChange({ latitude: null, longitude: null, address: null, countryCode: null});
    }
  };

  return (
    <div className={classes.root}>
      <div>
        <Typography className={classes.subtitle}>{title}</Typography>
        {subtitle &&
          <Typography className={classes.subtitleItalic}>
            {subtitle}
          </Typography>
        }
        <Paper className={classes.paper}>
          <div className={classes.geosuggest}>
            <Geosuggest
              autoFocus={autoFocus}
              initialValue={location.address}
              onSuggestSelect={(suggestion)=> onSuggestionSelected(suggestion)}
            />
          </div>
          {(location.latitude != null) &&
            <div style={{ height: '220px', marginBottom: 20, width: '100%', boxShadow: '0 1px 3px 0px rgba(180, 180, 180, .6)' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY}}
                    center={{
                        lat: Number(location.latitude+0.0002),
                        lng: Number(location.longitude)
                    }}
                    defaultZoom={18}
                    yesIWantToUseGoogleMapApiInternals
                    options={{
                        panControl: false,
                        mapTypeControl: false,
                        scrollwheel: false,
                        scaleControl: false,
                        fullscreenControl: false,
                        streetViewControl: false,
                        zoomControl: false,
                        styles:[]
                    }}
                >
                  <MarkerComponent
                      markerPhoto={markerPhoto}
                      markerName={markerName}
                      lat={Number(location.latitude)}
                      lng={Number(location.longitude)}
                  />
                </GoogleMapReact>
            </div>
          }
        </Paper>
        {(errorMessage )&&
          <Typography className={classes.error}>{errorMessage}</Typography>
        }
      </div>

    </div>
  );
}