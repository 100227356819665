const styles = theme => ({
    logo: {
        width:'auto',
        height:30,
        marginLeft:10,
        paddingReft:0,
        paddingTop:-2,
        position:'fixed',
        fontSize: 17,
        marginTop: 0,
        [theme.breakpoints.up('sm')]: {
          height: 34,
          marginTop: 0,
          marginLeft: 14,
        },
        [theme.breakpoints.up('md')]: {
          height:40,
          marginTop: -2.5,
          marginLeft: 0,
          display: 'inline',
        }
    },
    logoSeamless: {
      width:'auto',
      height:30,
      marginLeft:10,
      paddingReft:0,
      paddingTop:-2,
      position:'fixed',
      fontSize: 17,
      marginTop: 0,
      [theme.breakpoints.up('sm')]: {
        height: 34,
        marginTop: 0,
        marginLeft: 14,
      },
      [theme.breakpoints.up('md')]: {
        height:40,
        marginTop: 15,
        marginLeft: 80,
        display: 'inline',
      }
    }
});
  
export default styles;
  