import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper, Select, InputBase, MenuItem, Chip, Checkbox, ListItemText } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import styles from './multi-select-styles';
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const { title, subtitle, placeholder, selects, errorMessage, onChange, values } = props;

  return (
    <div className={classes.root}>
      <Grid container className={classes.grid}>
        <Grid item>
          <Typography className={classes.subtitle}>{title}</Typography>
        </Grid>
      </Grid>
      {subtitle &&
        <Typography className={classes.subtitleItalic}>
          {subtitle}
        </Typography>
      }
      {selects.map((select, indexSelect) => {
        return (
          <div className={classes.selectO}>
            <Grid container className={classes.grid}>
              <Grid item>
                <Typography className={classes.subtitle}>{select.subQuestionText}</Typography>
              </Grid>
              <Grid item>
                <KeyboardArrowDown className={classes.icon}/>
              </Grid>
            </Grid>
            {select.subQuestionInstruction &&
              <Typography className={classes.subtitleItalic}>
                {select.subQuestionInstruction}
              </Typography>
            }
            <Paper className={classes.paper}>
            <div>
              {(values[indexSelect]['subAnswers'].length === 0) &&
                <Typography className={classes.helperText}>
                  Please select from the list below
                </Typography>
              }

              <Select
                multiple
                fullWidth
                value={values[indexSelect]['subAnswers']}
                onChange={(event) => onChange(indexSelect, event)}
                input={<InputBase />}
                classes={{
                  icon: classes.hideInputBase,
                  root: classes.select
                }}
                renderValue={selected => (
                  <div className={classes.chips}>
                    {selected.map(value=> (
                      <Chip key={value} label={value} className={classes.chip} />
                    ))}
                  </div>
                )}
              >
                {select.subAnswers.map(item => (
                  <MenuItem value={item.answerText}>
                    <Checkbox checked={values[indexSelect]['subAnswers'].indexOf(item.answerText) > -1} />
                    <ListItemText primary={item.answerText} />
                  </MenuItem>
                ))}
              </Select>
            </div>
        </Paper>
        </div>
       )})}
      {(errorMessage )&&
        <Typography className={classes.error}>{errorMessage}</Typography>
      }
    </div>
  );
}

