const styles = theme => ({
  root: {
    marginTop: 0,
    marginBottom: 0
  },
  title: {
    fontFamily: 'proximaNova-bold',
    fontSize: 18,
    marginBottom: 34
  },
  contentRoot: {
    marginTop: 110,
    marginBottom: 40
  },
  typo: {
    fontWeight: 500,
    marginBottom: 15
  },
  backButton: {
    position: 'absolute',
    boxShadow: 'none',
    marginLeft: -32,
    marginRight: 12,
    marginTop: 22,
    backgroundColor: 'rgba(208,209,223,1)',
    margin: 0,
    padding: 0,
    width: 0,
    height: 40,
    '&:hover': {
      backgroundColor: 'rgba(208,209,223,1)',
      boxShadow: 'none'
    },
    '&:disabled': {
      backgroundColor: 'rgba(208,209,223,1)',
      boxShadow: 'none'
    },
    '&:highlight': {
      backgroundColor: 'rgba(208,209,223,1)',
      boxShadow: 'none'
    },
    '&:selected': {
      backgroundColor: 'rgba(208,209,223,1)',
      boxShadow: 'none'
    },
  },
  nextButtonInactive: {
    position: 'relative',
    marginLeft: 0,
    marginTop: 0,
    boxShadow: 'none',
    minWidth: 90,
    width: 'auto',
    maxWidth: 120,
    height: 40,
    backgroundColor: 'rgba(208,209,223,1)',
    color: 'white',
    textTransform: 'none',
    fontFamily: 'proximaNova-bold',
    fontSize: 13,
    '&:hover': {
      backgroundColor: 'rgba(208,209,223,1)',
      boxShadow: 'none'
    }
  },
  nextButton: {
    position: 'relative',
    marginLeft: 0,
    marginTop: 0,
    boxShadow: 'none',
    minWidth: 90,
    width: 'auto',
    maxWidth: 120,
    height: 40,
    backgroundColor: theme.palette.tertiary.main,
    color: 'white',
    textTransform: 'none',
    fontFamily: 'proximaNova-bold',
    fontSize: 13,
    '&:hover': {
      backgroundColor: theme.palette.tertiary.light,
      boxShadow: 'none'
    }
  },
  backArrowIcon: {
    color: 'white',
    fontFamily: 'proximaNova-bold',
    padding: 0,
    margin: 0,
    marginLeft: 30,
    marginTop: 0,
    width: 20,
    height: 'auto'
  },
  subtitle: {
    padding: 0,
    marginTop: 34,
    marginBottom: 0,
    paddingTop: 0,
    fontFamily: 'proximaNova-bold',
    fontSize: 14
  },
  subtitleItalic: {
    marginTop: 4,
    marginBottom: 18,
    marginRight: '30%',
    fontSize: 13,
    fontFamily: 'proximaNova-italic',
    lineHeight: 1.25,
    color: 'rgba(134,134,134,1)'
  },
  icon: {
    marginTop: 37,
    marginLeft: 6
  },
  gridPaper2: {
    marginTop: 6
  },
  gridPaperSub: {
    marginTop: 6
  },
  button: {
    position: 'absolute',
    margin: 0,
    padding: 0,
    width: '65%',
    maxWidth: 300,
    height: 60
  },
  pageTitle: {
    marginLeft: 18,
    marginTop: 10,
    fontSize: 14
  },
  subcategoryTitle: {
    marginLeft: 24,
    marginTop: 4,
    fontSize: 14,
    color: 'black',
    fontFamily: 'proximaNova-regular'
  },
  categoryTitle: {
    marginLeft: 4,
    marginTop: 7,
    fontSize: 14,
    color: theme.palette.primary.light
  },
  helperText: {
    color: 'rgba(160,160,160,1)',
    marginLeft: 24,
    fontSize: 14
  },
  logo: {
    marginLeft: 12,
    width: 40,
    height: 40,
    borderRadius: 20,
    objectFit: 'cover'
  },
  categoryImage: {
    marginLeft: 12,
    marginTop: 4,
    width: 35,
    height: 35,
    color: theme.palette.primary.light
  },
  menuRoot: {
    minWidth: 200,
    width: 'auto',
    height: 60
  },
  menuRootCategories: {
    minWidth: 200,
    width: 'auto',
    height: 40
  },
  menuImage: {
    width: 35,
    height: 35,
    marginTop: 4
  },
  menuImageFacebook: {
    marginTop: 2,
    width: 40,
    height: 40,
    borderRadius: 20,
    objectFit: 'cover'
  },
  menuTitle: {
    marginLeft: 6,
    marginTop: 7,
    fontSize: 14
  },
  menuTitleFacebook: {
    marginLeft: 18,
    marginTop: 12,
    fontSize: 14
  },
  menuTitleSub: {
    marginLeft: 6,
    marginTop: -3,
    fontSize: 14
  },
  lookingForTitle: {
    fontSize: 14
  },
  dividerSubcategories: {
    marginLeft: 18,
    marginRight: 18,
    marginTop: 5,
    marginBottom: 5,
  },
  dividerCategories: {
    marginLeft: 58,
    marginRight: 18,
    marginTop: 5,
    marginBottom: 5,
  },
  dividerFacebookPages: {
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 75,
    marginRight: 18
  },
  menuHeight: {
    maxHeight: 500,
    height: 'auto'
  },
  grid: {
    width: '100%',
    alignItems: 'center'
  },
  item: {
      width: 'calc(50% - 12px)',
      maxWidth: 300
  },
  item2: {
      width: 'calc(50% - 12px)',
      maxWidth: 300
  },
  spacer: {
      width: 24
  },
  fullField: {
      width: '100%',
      maxWidth: 624
  },
  gridText: {
  },
  gridPaper: {
      height: 40,
  },
  paper: {
      width: '100%',
      height: 60,
      paddingLeft: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      boxShadow: '0 1px 3px 0px rgba(180, 180, 180, .4)'
  },
  errorText: {
    fontSize: 13,
    color: 'red',
    marginLeft: 5,
    marginTop: 4
  },
  input: {
      marginLeft: 24,
      fontSize: 14,
      width: 'auto',
      minWidth: '90%'
  },
  bottomButtons: {
    marginTop: 50,
    marginBottom: 50
  }
});

export default styles;
