import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Loader from 'components/ui/loader';

const styles = theme => ({
    root: {
        position: 'fixed',
        width: '100%;',
        height: '100%',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,1)',
        zIndex: 2000,
        cursor: 'pointer'
    }
});
const useStyles = makeStyles(styles);

export default function (props) {
    const classes = useStyles();
    return (
        <div>
            {props.children}
        </div>
    );
}
/*
{(ui.fullScreenOverlayLoader === true) &&
                <div className={classes.root}>
                    <Loader full />
                </div>
            } 
*/