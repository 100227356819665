const styles = theme => ({
    title: {
        fontFamily: 'proximaNova-bold',
        fontSize: 32,
        color: 'black'
    },
    subtitle: {
        fontFamily: 'proximaNova-bold',
        fontSize: 14,
        color: 'black'
    },
    standard: {
        fontFamily: 'proximaNova-regular',
        fontSize: 14,
        color: 'black'
    }
});
  
export default styles;
  