import React from 'react';
import { BOVLoader } from 'components/ui';
import Routes from './app-routes';

export default function () {
    return (
        <React.Fragment>
            <React.Suspense fallback={<BOVLoader full/>}>
                <Routes />
            </React.Suspense>
        </React.Fragment>
    );
}