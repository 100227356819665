import React, { useContext, useEffect } from 'react';
import { AuthenticationContext } from 'repository/stores/authentication-store';
import { BOVLoader } from 'components/ui';

// Children
import { authenticateUser } from 'repository/actions/authentication'; 

export default function (props) {

    const [auth, setAuthContext] = useContext(AuthenticationContext);

    useEffect(() => {
        authenticateUser(setAuthContext)
    },[auth.status]);

    if (auth.user === null) {
        return <BOVLoader full/>;
    } else {
        return props.children;
    }
}