import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { NapoButton } from 'components/ui';
import { withRouter } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Divider, Button, Typography } from '@material-ui/core';

// Styles
import styles from './popup-styles';
const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  const classes = useStyles();
  const { open, title, handleCancel, actionButtonTitle, handleAction, content, disabled, subtitle, hideCancelButton, cancelButtonTitle, customActionButton, center, fullWidth, maxWidth, fullScreen, error } = props;

  const onKeyPressDown = (event) => {
    if(event.keyCode == 13){
       handleAction();
     }
  }

  let bottomButtons = classes.bottomButtons;

  if (center) {
    bottomButtons = classes.centerbottomButtons;
  }

  let isFullWidth = false;
  let MaxWidth = false;
  if (fullWidth) {
    isFullWidth = fullWidth;
  }
  if (maxWidth) {
    MaxWidth = maxWidth;
  }

  let isFullScreen = false;
  if (fullScreen) {
    isFullScreen = fullScreen;
  }

  return (
      <div>
          <Dialog open={open} fullWidth={isFullWidth}  fullScreen={isFullScreen} maxWidth={MaxWidth} onClose={()=> handleCancel()} onKeyDown={(event) => onKeyPressDown(event)}>
            <DialogTitle>
                <Typography className={classes.title}>
                    {title.toUpperCase()}
                </Typography>
                {subtitle &&
                    <Typography className={classes.subtitle}>
                        {subtitle}
                    </Typography>
                }
                {error &&
                  <Typography className={classes.errorText}>
                    {error}
                  </Typography>
                }
            </DialogTitle>
            <DialogContent className={classes.content}>
                {content}
            
            </DialogContent>
            <DialogActions className={bottomButtons}>
                {!hideCancelButton &&
                    <Button disabled={disabled} className={classes.cancelButton} disableElevation onClick={() => handleCancel()}>
                        {cancelButtonTitle &&
                          <div>
                            {cancelButtonTitle}
                          </div>
                        }
                        {!cancelButtonTitle &&
                          <div>
                            Cancel
                          </div>
                        }
                    </Button>
                }
                {customActionButton &&
                    <div>
                        {customActionButton}
                    </div>
                }
                {!customActionButton &&
                    <div className={classes.buttonWrapper}>
                        <Button className={classes.greenButton} disabled={disabled} disableElevation onClick={() => handleAction()} variant="contained">
                          <div>
                            {(!disabled) &&
                              <div>
                                {actionButtonTitle}
                              </div>
                            }
                          </div>
                        
                        </Button>
                        {disabled && <CircularProgress size={16} className={classes.buttonProgress} />}
                    </div>
                }
            </DialogActions>
          </Dialog>
      </div>
  );
})