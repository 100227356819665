import React from 'react';
import { initClient } from 'utils/api';
import Theme from 'utils/theme';
import AppProviders from './app-providers';
import AppView from './app-view';
import AppLoader from './app-loader';
import AppAuthentication from './app-authentication';

export default function () {

    // Initialise API
    initClient(function (err) {
        //console.log('client is up and running');
    });
    
    // Return Main App Component
    return (
        <AppProviders>
            <Theme>
                <AppLoader>
                    <AppAuthentication>
                        <AppView />
                    </AppAuthentication>
                </AppLoader>
            </Theme>
        </AppProviders>
    )
}