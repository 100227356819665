const styles = theme => ({
  buttonInactive: {
    position: 'relative',
    marginLeft: 0,
    marginTop: 0,
    marginBottom: 40,
    boxShadow: 'none',
    minWidth: 100,
    width: 'auto',
    maxWidth: 120,
    height: 40,
    backgroundColor: 'rgba(208,209,223,1)',
    color: 'white',
    textTransform: 'none',
    fontFamily: 'proximaNova-bold',
    fontSize: 13,
    '&:hover': {
      backgroundColor: 'rgba(208,209,223,1)',
      boxShadow: 'none'
    }
  },
  buttonActive: {
    position: 'relative',
    marginLeft: 0,
    marginTop: 0,
    marginBottom: 40,
    boxShadow: 'none',
    minWidth: 100,
    width: 'auto',
    maxWidth: 120,
    height: 40,
    backgroundColor: theme.palette.tertiary.main,
    color: 'white',
    textTransform: 'none',
    fontFamily: 'proximaNova-bold',
    fontSize: 13,
    '&:hover': {
      backgroundColor: theme.palette.tertiary.light,
      boxShadow: 'none'
    }
  },  
  buttonIcon: {
    position: 'absolute',
    fontFamily: 'proximaNova-bold',
    width: 16,
    height: 'auto',
    marginLeft: 'calc(50% - 70px)',
    marginTop: -19
  },
  buttonProgress: {
    marginTop: 6,
  },
  arrowButton: {
    fontSize: 12,
    display: 'inline-block',
    fontFamily: 'proximaNova-bold',
    boxShadow: 'none',
    backgroundColor: theme.palette.tertiary.main,
    color: 'white',
    textTransform: 'none',
    width: '100%',
    height: 46,
    borderRadius: 4,
    paddingTop: 10,
    marginLeft: 0,
    '&:hover': {
      backgroundColor: theme.palette.tertiary.light,
      borderColor: theme.palette.tertiary.light,
      boxShadow: 'none',
    }
  }
});
  
export default styles;
  