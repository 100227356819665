const styles = theme => ({
  root: {
    marginTop: 0,
    marginBottom: 0
  },
  title: {
    fontFamily: 'proximaNova-bold',
    fontSize: 32
  },
  subtitle: {
    padding: 0,
    marginTop: 0,
    marginBottom: 4,
    paddingTop: 0,
    fontFamily: 'proximaNova-bold',
    fontSize: 14
  },
  subtitleItalic: {
    marginTop: 18,
    marginBottom: 18,
    marginRight: '30%',
    fontSize: 13,
    fontFamily: 'proximaNova-italic',
    lineHeight: 1.25,
    color: 'rgba(134,134,134,1)',
    width: '100%'
  },
  geoField: {
    boxShadow: 'none',
    border: 'none'
  }, 
  limiter: {
    color: 'rgba(134,134,134,1)',
    fontSize: 13
  },
  limiterRed: {
    color: theme.palette.button.red.active,
    fontSize: 13
  },
  descriptionField: {
    paddingRight: 70,
    marginTop: -26,
    fontSize: 15,
    fontFamily: 'proximaNova-regular',
  },
  icon: {
    marginTop: -4,
    marginLeft: 5
  },
  grid: {
    marginTop: 42
  },
  gridPaper: {
    height: 40,
  },
  gridPaper2: {
    marginTop: 6
  },
  button: {
    position: 'absolute',
    margin: 0,
    padding: 0,
    width: '65%',
    height: 60
  },
  pageTitle: {
    marginLeft: 18,
    marginTop: 10,
    fontSize: 14
  },
  categoryTitle: {
    marginLeft: 4,
    marginTop: 7,
    fontSize: 14,
    color: theme.palette.primary.light
  },
  paper: {
    width: 'auto',
    minHeight: 55,
    maxHeight: 'auto',
    maxWidth: 624,
    paddingLeft: 20,
    paddingRight: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    boxShadow: '0 1px 3px 0px rgba(180, 180, 180, .4)'
  },
  geosuggest: {
    width: '100%'
  },
  helperText: {
    color: 'rgba(160,160,160,1)',
    marginLeft: 24,
    fontSize: 14
  },
  logo: {
    marginLeft: 12,
    width: 40,
    height: 40,
    borderRadius: 20,
    objectFit: 'cover'
  },
  categoryImage: {
    marginLeft: 12,
    marginTop: 4,
    width: 35,
    height: 35,
    color: theme.palette.primary.light
  },
  menuRoot: {
    minWidth: 200,
    width: 'auto',
    height: 60
  },
  menuRootCategories: {
    minWidth: 200,
    width: 'auto',
    height: 40
  },
  menuImage: {
    width: 35,
    height: 35,
    marginTop: 4
  },
  menuImageFacebook: {
    marginTop: 2,
    width: 40,
    height: 40,
    borderRadius: 20,
    objectFit: 'cover'
  },
  menuTitle: {
    marginLeft: 6,
    marginTop: 7,
    fontSize: 14
  },
  menuTitleFacebook: {
    marginLeft: 18,
    marginTop: 12,
    fontSize: 14
  },
  lookingForTitle: {
    fontSize: 14
  },
  dividerCategories: {
    marginLeft: 58,
    marginRight: 18,
    marginTop: 5,
    marginBottom: 5,
  },
  dividerFacebookPages: {
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 75,
    marginRight: 18
  },
  menuHeight: {
    maxHeight: 500,
    height: 'auto'
  },
  picker: {
    width: 35,
    height: 'auto'
  },
  text: {
      width: 'auto',
      fontSize: 14,
      lineHeight: 1.25,
      alignItems: 'left',
      textAlign: 'left',
      marginLeft: 13
  },
  icon: {
      width: 30,
      height: 30,
      borderRadius: 15,
      border:'1px solid rgba(255,255,255,0.5)',
      boxShadow: '0 0 1px 1px white'
  },
  businessName: {
      display: 'flex',
      lineHeight: 1.25,
      width: 145,
      backgroundColor: theme.palette.primary.light,
      color: 'white',
      padding: 13,
      borderRadius: 10,
      marginLeft: -58,
      marginBottom: 15,
      marginRight: 15,
      paddingRight: 15,
      alignItems: 'center',
      align: 'center',
      border: '0px solid black'
  },
  marker: {
      marginLeft: -17,
      marginTop: -116,
  },
  iconSpacing: {
      marginLeft: 8
  },
  iconOverlay: {
    color: theme.palette.secondary.main,
    marginTop: 2,
    marginLeft:  -10,
    width: 20,
    height: 20
  },
  error: {
    fontSize: 13,
    color: 'red',
    marginLeft: 5,
    marginTop: 4
  }
});

export default styles;
