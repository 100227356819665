import React from 'react';
import { Route, Switch } from 'react-router';
import { BrowserRouter as Router, Redirect } from 'react-router-dom';
import { PublicRoute, PrivateRoute } from 'components/route';
import withMemo from 'utils/helpers/withMemo';
import ScrollToTop from 'utils/helpers/scrollToTop';

const NotFound = React.lazy(() => import ('routes/not-found'));
const Login = React.lazy(() => import('routes/login'));
const PublicQuestionnaire = React.lazy(() => import('routes/public-questionnaire'));
const SingleQuestionnaire = React.lazy(() => import ('routes/single-questionnaire'));
const UserQuestionnaires = React.lazy(() => import ('routes/user-questionnaires'));

export default function Routes() {
    return(
        <Router>
            <ScrollToTop>
                <Switch>
                    <PublicRoute exact path="/404" component={() => <NotFound />} />
                    <PublicRoute exact path="/login" component={() => <Login />} />
                    <PrivateRoute exact path="/questionnaires" component={() => <UserQuestionnaires />} />
                    <PrivateRoute path="/questionnaire/:id" component={withMemo(() => <SingleQuestionnaire />,[])} />\
                    <Route exact path="/public-questionnaire/:id" component={withMemo(() => <PublicQuestionnaire />,[])}  />
                    <Redirect exact from="/" to="/questionnaires" />
                    <Route component={() => <NotFound />} />
                </Switch>
            </ScrollToTop>
        </Router>
    )
}