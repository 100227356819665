import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthenticationContext } from 'repository/stores/authentication-store';

export default function ({ component: Component, ...rest }) {
  const [state] = useContext(AuthenticationContext);

    return (
      <Route {...rest} 
        render={(props) => (state.status === false)? <Component {...props} /> : <Redirect to='/questionnaires' />} 
      
      
      />
    );
}


//`/login/${props.location.search}`