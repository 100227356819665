import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

// Assets
import LogoImage from 'assets/images/logo/bov-logo-light.png';

// Styles
import styles from './app-bar-logo-styles';
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();

  let { seamless } = props;

  let logoClass = classes.logo;
  if (seamless) {
    logoClass = classes.logoSeamless;
  }
  return (
    <div className={logoClass}>
      <a href="/">
        <img src={LogoImage} alt="Logo" className={classes.logo} />
      </a>
    </div>
  );
}