import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import styles from './label-styles';
const useStyles = makeStyles(styles);

export default function (props) {
    const classes = useStyles();
    const { type, text, className } = props;

    let classType = classes.standard;
    if (type) {
        if (type === 'title') {
            classType = classes.title;
        }
        if (type === 'subtitle') {
            classType = classes.subtitle;
        }
        if (type === 'standard') {
            classType = classes.standard;
        }
    }

    return (
        <div className={className}>
            <Typography className={classType}>
                {text}
            </Typography>
        </div>
    )
};
