import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack';

// Config
import themeConfig from './config';
const theme = createMuiTheme(themeConfig);

export default function ThemeWrapper(props) {
  return (
    <MuiThemeProvider theme={theme}>
        <CssBaseline />
          <SnackbarProvider 
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            autoHideDuration={1500}
            resumeHideDuration={1000}
          >
          {props.children}
          </SnackbarProvider>
    </MuiThemeProvider>
  );
}
