import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography , Divider, CardContent, CardHeader, Card} from '@material-ui/core';
import Loader from 'components/ui/loader';

// Styles
import styles from './section-styles';
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const { title, icon, loading, actionName, handleAction, inputFile, handleCapture, sectionDescription, greyActionButton } = props;

  let inputF;
  if (inputFile != null) {
    if (inputFile === true) {
        inputF = true;
    } else if (inputFile === false) {
        inputF = false;
    }
  } else {
      inputF = false;
  }

  let actionButtonClass = classes.button;

  if (greyActionButton) {
      actionButtonClass = classes.buttonGrey;
  }

  return (
    <Card elevation={0} className={classes.smallCard}>
        <CardHeader className={classes.header} action={(actionName && !loading) &&
            <div>
                {(inputF === true) &&
                    <div>
                        <input
                            accept="image/*"
                            className={classes.input}
                            id={title}
                            multiple={false}
                            type="file"
                            onChange={handleCapture}
                        />
                        <label htmlFor={title}>
                            <Button variant="contained" component="span" color="primary" className={actionButtonClass} disableElevation>
                                {actionName}
                            </Button>
                        </label>
                    </div>
                }
                {(inputF === false) &&
                    <Button variant="contained" color="primary" onClick={() => handleAction()} className={actionButtonClass} disableElevation>
                        {actionName}
                    </Button>
                }
            </div>
        }
        title={
            <div className={classes.flex}>
                {icon &&
                    <img src={icon} className={classes.icon}></img>
                }
                <Typography className={classes.title}>
                    {title.toUpperCase()}
                </Typography>
            </div>
        }
        />
        <CardContent>
            {!loading && 
                <Typography className={classes.dummyText}>
                {sectionDescription}
                </Typography>
            }
        </CardContent>
        <CardContent>
            {loading &&
                <Loader />
            }
            {!loading && 
                <div>{props.children}</div>
            }
        </CardContent>
    </Card>
  );
}