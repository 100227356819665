import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';

// Children
import Account from './account';
import Logo from './logo';

// Styles
import styles from './app-bar-styles';
const useStyles = makeStyles(styles);

function ApplicationBar(props) {
  const classes = useStyles();

  const { showAccount, hideLogo, seamless, transparent, dark } = props;

  let elevation = 2;
  let appBarStyle = classes.appBar;
  if (seamless) {
    elevation = 0;
    appBarStyle = classes.appBarSeamless;
  }
  if (transparent) {
    elevation = 0;
    appBarStyle = classes.appBarTransparent;
  }
  return (
    <AppBar elevation={elevation} className={appBarStyle} zdepth={0}>
        <Toolbar>
            {(showAccount) &&
              <Account seamless={seamless} dark={dark}/>
            }
            {(!hideLogo) &&
              <Logo seamless={seamless}/>
            }
            
        </Toolbar>
    </AppBar>
  );
}
export default withRouter(ApplicationBar);