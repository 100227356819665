// Colors
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';

import ProximaNovaRegular from './Proxima-Nova-Reg.woff';
import ProximaNovaBold from './Proxima-Nova-Bold.woff';
import ProximaNovaItalic from './Proxima-Nova-Italic.woff';
import LaBelleAuroreFont from './La-Bello-Aurore-Regular.woff';

const proximaNovaRegular= {
  fontFamily: 'proximaNova-regular',
  src: `
    url(${ProximaNovaRegular}) format('woff2')
  `
};

const proximaNovaBold= {
  fontFamily: 'proximaNova-bold',
  fontSize: 26,
  src: `
    url(${ProximaNovaBold}) format('woff')
  `
};

const proximaNovaItalic= {
  fontFamily: 'proximaNova-italic',
  src: `
    url(${ProximaNovaItalic}) format('woff')
  `
};

const LaBelleAurore = {
  fontFamily: 'laBelleAurore-regular',
  src: `
    url('${LaBelleAuroreFont}') format('woff')
  `
}

const themeConfig = [
  {
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [proximaNovaRegular, proximaNovaBold, proximaNovaItalic, LaBelleAurore],
        },
      },
    },
    typography: {
      fontFamily: ['proximaNova-regular, proximaNova-bold, proximaNova-italic, laBelleAurore-regular'].join(','),
      fontSize: 13
    },
    button: {
      fontFamily: 'proximaNova-bold'
    },
    shape: {
      borderRadius: 4,
      borderStyle: 'solid',
      borderColor: 'rgba(23,60,151,0.7)',
      borderWidth: 1
    },
    palette: {
      background: {
        default: 'rgba(241,241,252,1)',
        grey: 'rgba(241,241,252,1)'
      },
      primary: {
        light: '#7f0955',
        main: '#7f0955',
        dark: '#7f0955',
        previousMain: 'rgba(26,76,197,1)'
      },
      secondary: {
        light: '#7f0955',
        main: '#7f0955',
        dark: '#7f0955'
      },
      tertiary: {
        light: '#7f0955',
        main: '#7f0955'
      },
      text: {
        dark: 'black'
      },
      button: {
        blue: {
          active: 'rgba(29,92,246,1)'
        },
        red: {
          active: red[600],
          selected: red[700]
        },
        grey: {
          active: grey[500],
          selected: grey[600]
        },
        green: {
          active: 'rgba(0,210,70,1)',
          selected: green[600]
        }
      },
    },
  }
]

export default themeConfig[0];
