import React from 'react';

export const AuthenticationContext = React.createContext();

const initialState = {
    "status": null,
    "user": null
};

const reducer = (state, action) => {
    switch (action.type) {
      case 'AuthenticateUser':
        return {
            status: action.payload.status,
            user: action.payload.user
        };
      default: throw new Error('Unexpected action');
    }
};

export const AuthenticationProvider = ({ children }) => {
  const contextValue = React.useReducer(reducer, initialState);
  return (
    <AuthenticationContext.Provider value={contextValue}>
      {children}
    </AuthenticationContext.Provider>
  );
};