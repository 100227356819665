import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper, Select, InputBase, MenuItem, Chip, Checkbox, ListItemText } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import { KeyboardArrowDown } from '@material-ui/icons';
import { BOVTextField, BOVRadio, BOVButton, BOVPopup } from 'components/ui';
import styles from './multi-field-styles';
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const { title, subtitle, placeholder, fields, errorMessage, onChange, values } = props;
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [openNew, setOpenNew] = useState(false);
  const [singularStructure, setSingularStruture] = useState([]);

  const [ options ] = useState({
    filter: false,
    search: false,
    print: false,
    download: false,
    fixedSelectColumn: false,
    fixedHeader: false,
    sort: false,
    viewColumns: false,
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
      return (
        <div></div>
      );
    },
    customToolbar: () => {
      return (
        <div style={{
          "padding-top": "24px",
          "margin-left": "0px"
        }}>
          <BOVButton
            text="Add New"
            onClick={() => setOpenNew(true)}
          >
          </BOVButton>
        </div>
      )
    },
    onRowsDelete: (rowsDeleted, data, newTableData) => {

      let currentAnswers = values;
      //alert(JSON.stringify(currentAnswers))
      //currentAnswers.splice(Number(rowsDeleted.data[0].dataIndex),1)
      //onChange(currentAnswers)
    }
  });
  

  useEffect(() => {
    let names = [];
    fields.map((field, indexField) => {
      names.push(field.fieldText);
    });
    setColumns(names);
  },[openNew]);

  useEffect(() => {
    let fData = [];
    for (const [index, field] of fields.entries()){
      if (field.fieldType == 'text') {
        fData[index] = {
          ...field,
          'answerText': ''
        };
      }
      if (field.fieldType == 'radio') {
        fData[index] = {
          ...field,
          'answerIndex': 0
        };
      }
    }

    setSingularStruture(fData);
  },[openNew])

  useEffect(() => {
    
    let newData = [];

    for (let value of values) {
      let row = [];
      for (let field of value) {
        if (field.fieldType == 'radio') {
          row.push(field.answers[field.answerIndex]['answerText']);
        }
        if (field.fieldType == 'text') {
          row.push(field.answerText);
        }
      }
      newData.push(row);
    }

    if (newData.length === 0) {
      setOpenNew(true)
    }
    setData(newData);

    // Parse values into data
  },[values])

  const onSubmitNew = () => {

    // Add to Values;
    let currentAnswers = [...values];
    currentAnswers.push(singularStructure)
    onChange(currentAnswers)
    setOpenNew(false);
    setSingularStruture([]);
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{title}</Typography>
      {subtitle &&
        <Typography className={classes.subtitleItalic}>
          {subtitle}
        </Typography>
      }
      <MUIDataTable
        data={data}
        columns={columns}
        options={options}
      />
      <BOVPopup
            title={title}
            subtitle={subtitle}
            open={openNew}
            fullWidth
            maxWidth={'sm'}
            handleCancel={() => setOpenNew(false)}
            handleAction={() => onSubmitNew()}
            actionButtonTitle="Add"
            content={
                <div className={classes.root}>
                   {((singularStructure.length) == (fields.length)) &&
                    <div>
                      {fields.map((field, indexField) => {
                        return (
                          <div className={classes.field0}>
                              {(field.fieldType == 'text') &&
                                <div>
                                  <BOVTextField 
                                    title={field.fieldText}
                                    subtitle={''}
                                    placeholder="Type something here..."
                                    onChange={(event) => {
                                      let structuredData = [...singularStructure];
                                      structuredData[indexField]['answerText'] = event.target.value;
                                      setSingularStruture(structuredData);
                                    }}
                                    value={singularStructure[indexField]['answerText']}
                                    autoFocus={(indexField == 0)}
                                    fullWidth
                                  />
                                </div>
                              }
                              {(field.fieldType == 'radio') &&
                                <div>
                                  <BOVRadio
                                    title={field.fieldText}
                                    subtitle={''}
                                    options={field.answers}
                                    onChange={(event) => { 
                                      let structuredData = [...singularStructure];
                                      structuredData[indexField]['answerIndex'] = Number(event.target.value);
                                      setSingularStruture(structuredData);
                                    }}
                                    value={singularStructure[indexField]['answerIndex']}
                                  />
                                </div>
                              }
                          </div>
                      )})}
                    </div>
                   }
                </div>
            }
        />
      {(errorMessage )&&
        <Typography className={classes.error}>{errorMessage}</Typography>
      }
    </div>
  );
}