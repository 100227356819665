import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper, InputBase, Button, Menu, MenuItem, Divider, Icon } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';

// Styles
import styles from './cascade-styles';
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const { title, subtitle, placeholder, options, errorMessage, onPrimaryChange, onSecondaryChange, primaryValue, secondaryValue} = props;
  const [categoryAnchorEl, setCategoryAnchorEl] = useState(null);
  const [subcategoryAnchorEl, setSubcategoryAnchorEl] = useState(null);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  useEffect(()=> {

    let cat = [];
    for (const [index, option] of options.entries()) {
      cat.push( {
        "answerText": option.answerText,
        "answerValue": index
      });
    }

    setCategories(cat);

  },[]);

  useEffect(() => {
    if ((primaryValue != null) && (options != null)) {
      for (const [index, option] of options.entries()) {
        if (index == primaryValue) {
          setSubcategories(option.subAnswers);
        }
      }
    }
  },[primaryValue])

  return (
      <div className={classes.root}>
        <Typography className={classes.title}>{title}</Typography>
        {(subtitle != null) &&
            <Typography className={classes.subtitle}>
             {subtitle}
            </Typography>
        }
       
        <Grid container className={classes.grid}>
        <Grid item>
          <Typography className={classes.subtitle}>Select here</Typography>
        </Grid>
        <Grid item>
          <KeyboardArrowDown className={classes.icon}/>
        </Grid>
      </Grid>
      {(categories.length > 0) &&
        <Grid container>
          <Grid item className={classes.item2}>
              <Paper className={classes.paper}>
                {(primaryValue === null) &&
                  <Typography className={classes.helperText}>
                    Click here to select an option
                  </Typography>
                }
                {(primaryValue != null) &&
                  <Typography className={classes.subcategoryTitle}>
                    {categories[primaryValue].answerText}
                  </Typography>
                }
                <Button className={classes.button} onClick={(event)=> setCategoryAnchorEl(event.currentTarget)} />
              </Paper>
          </Grid>
          <Grid item className={classes.spacer}>
          </Grid>
          {((subcategories.length > 0)) &&
            <Grid item  className={classes.item2}>
              <Paper className={classes.paper}>
                {(secondaryValue === null) &&
                  <Typography className={classes.helperText}>
                    Click here to select a sub-option
                  </Typography>
                }
                {(secondaryValue != null) &&
                  <Typography className={classes.subcategoryTitle}>
                    {subcategories[secondaryValue].answerText}
                  </Typography>
                }
                <Button className={classes.button} onClick={(event)=> setSubcategoryAnchorEl(event.currentTarget)} />
              </Paper>
          </Grid>
          }
      </Grid>
      }
      
      <Menu
        id="category-menu"
        anchorEl={categoryAnchorEl}
        value={primaryValue}
        keepMounted
        open={Boolean(categoryAnchorEl)}
        onClose={(event) =>  setCategoryAnchorEl(null)}
        className={classes.menuHeight}
      >
        {categories.map((item, index) =>  {
          return (
           <div>
              <MenuItem value={index} className={classes.menuRootCategories} onClick={(event) => {onPrimaryChange(index); setCategoryAnchorEl(null);}}>
                <Grid container className={classes.gridPaperSub}>
                  <Grid item>
                    <Typography className={classes.menuTitleSub}>
                      {item.answerText}
                    </Typography>
                  </Grid>
                </Grid>
              </MenuItem>
              {(index < categories.length -1) &&
                <Divider className={classes.dividerSubcategories}/>
              }
           </div>
          )
        })}
      </Menu>

      {((subcategories.length > 0)) &&
          <Menu
          id="subcategory-menu"
          anchorEl={subcategoryAnchorEl}
          value={secondaryValue}
          keepMounted
          open={Boolean(subcategoryAnchorEl)}
          onClose={(event) =>  setSubcategoryAnchorEl(null)}
          className={classes.menuHeight}
        >
          {subcategories.map((item, index) =>  {
            return (
            <div>
                <MenuItem value={index} className={classes.menuRootCategories} onClick={(event) => {onSecondaryChange(index); setSubcategoryAnchorEl(null);}}>
                <Grid container className={classes.gridPaperSub}>
                  <Grid item>
                    <Typography className={classes.menuTitleSub}>
                      {item.answerText}
                    </Typography>
                  </Grid>
                </Grid>
              </MenuItem>
              {(index < subcategories.length -1) &&
                <Divider className={classes.dividerSubcategories}/>
              }
            </div>
            )
          })}
        </Menu>
      } 
        {(errorMessage )&&
          <Typography className={classes.error}>{errorMessage}</Typography>
        }
      </div>
  );
}