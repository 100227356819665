import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, InputBase, Paper } from '@material-ui/core';

// Styles
import styles from './text-field-styles';
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const { title, subtitle, placeholder, value, errorMessage, onChange, multiline, fullWidth, autoFocus, rows, maxLength } = props;

  return (
      <div>
        <Typography className={classes.title}>{title}</Typography>
        {(subtitle != null) &&
            <Typography className={classes.subtitle}>
             {subtitle}
            </Typography>
        }
        <Paper className={classes.paper}>
          {props.children}
          <InputBase
            className={classes.descriptionField}
            onChange={onChange}
            value={value}
            autoFocus={autoFocus}
            inputProps={{
              maxLength: maxLength ? maxLength : 50
            }}
            placeholder={placeholder}
            fullWidth={fullWidth ? fullWidth : true}
            multiline={multiline ? multiline : false}
            rows={rows}
          />
        </Paper>
        {(errorMessage )&&
          <Typography className={classes.error}>{errorMessage}</Typography>
        }
      </div>
  );
}