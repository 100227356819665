import { getClient } from 'utils/api';

export const authenticateUser = async (setAuthContext) => {
    const apiClient = getClient();
    try {
        let authentication = await apiClient.reAuthenticate();

        setAuthContext({
            type: 'AuthenticateUser',
            payload: { 
                'status': true, 
                'user': authentication.user
            }
        });
    } catch (e) {
        setAuthContext({
            type: 'AuthenticateUser',
            payload: { 
                'status': false, 
                'user': {} 
            }
        });
    }
}

export const loginWithEmail = (email, password) => {
    return new Promise(async (resolve, reject) => {
        let apiClient = getClient();
        try {
            let auth = await apiClient.authenticate({
                strategy: 'local',
                email: email,
                password: password
            });
            resolve(auth);
        } catch (e) {
            reject(e)
        }
    });
}


export const logOut = async (auth) => {
    const apiClient = getClient();
    await apiClient.logout();
    window.location.href = "/";
}