import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { capitalizeFirstLetter } from 'utils/helpers/string';
import { Button, CircularProgress } from '@material-ui/core';
import { ArrowForwardRounded } from '@material-ui/icons';

// Styles
import styles from './button-styles';
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const { text, disabled, onClick, className, loading, type } = props;

  let modifiedText = capitalizeFirstLetter(text.toLowerCase());

  if (!type) {
    return (
      <div className={className}>
        <Button  className={disabled ? classes.buttonInactive : classes.buttonActive} disabled={disabled || loading} variant="contained" onClick={onClick}>
          {loading &&
            <div>
              <CircularProgress size={16} className={classes.buttonProgress} />
            </div>
          }
          {!loading &&
            <div>{modifiedText}</div>
          }
        </Button>
      </div>
      
    );
  } else if (type == 'arrow'){
    return (
      <div className={className}>
        <Button 
          className={classes.arrowButton}
          variant="contained"
          onClick={() => onClick()}
          disabled={disabled || loading}
        >
          {loading &&
            <div>
              <CircularProgress size={16} className={classes.buttonProgress} />
            </div>
          }
          {!loading &&
           <div>
              <div>{modifiedText}</div>
              <ArrowForwardRounded className={classes.buttonIcon}/>
           </div>
          }
          
        </Button>
      </div>
    )
  } else {
    return <div></div>;
  }
  
}