import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Paper } from '@material-ui/core';
import MuiPhoneNumber from 'material-ui-phone-number-input';

// Styles
import styles from './phone-number-styles';
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const { title, subtitle, placeholder, onChangePhoneNumber, phoneNumber, defaultCountry, errorMessage } = props;
  const [phoneError, setPhoneError] = useState(null);

  let countryDefault = '';
  if (defaultCountry) {
    countryDefault = defaultCountry.toLowerCase();
  }
  return (
    <div className={classes.root}>
      <Typography className={classes.subtitle}>{title}</Typography>
      {subtitle &&
        <Typography className={classes.subtitleItalic}>
        {subtitle}
        </Typography>
      }
      <Paper className={classes.paper}>
        <MuiPhoneNumber 
            className={classes.textField}
            placeholder={placeholder}
            disableAreaCodes
            countryCodeEditable={false}
            error={(phoneError != null)}
            helperText={phoneError}
            required
            InputProps={{
              classes: {
                input: classes.field,
                underline: classes.underline
              },
            }}
            inputProps={{
              maxLength: 100
            }}
            defaultCountry={countryDefault}
            value={phoneNumber}
            onChange={(value) => onChangePhoneNumber(value)}
        />
      </Paper>
      {(errorMessage )&&
        <Typography className={classes.error}>{errorMessage}</Typography>
      }
    </div>
  );
}