const styles = theme => ({
    root: {
        position: 'relative',
        marginLeft: 0,
        
    },
    title: {
        marginTop: 12,
        fontFamily: 'proximaNova-bold',
        color: theme.palette.primary.light,
        fontSize: 18
    },
    subtitle: {
        marginTop: 8,
        marginBottom: 0,
        marginRight: 0,
        fontSize: 13,
        fontFamily: 'proximaNova-italic',
        lineHeight: 1.25,
        color: 'rgba(134,134,134,1)'
    },
    divider: {
        marginLeft: 24,
        marginRight:24,
        marginBottom: 12,
        width: 15,
        height: 1.5,
        backgroundColor: theme.palette.secondary.main
    },
    description: {
        marginRight: '30%',
        fontSize: 13,
        fontFamily: 'proximaNova-italic',
        lineHeight: 1.25,
        color: 'rgba(134,134,134,1)'
    },
    content: {
        marginTop: 20
    },
    greenButton: {
        marginBottom: 15,
        marginRight: 15,
        minHeight: 30,
        fontSize: 12,
        fontFamily: 'proximaNova-bold',
        backgroundColor: theme.palette.tertiary.main,
        color: 'white',
        textTransform: 'none',
        borderRadius: 4,
        '&:hover': {
            backgroundColor: theme.palette.tertiary.light,
            boxShadow: 'none'
        }
    },
    cancelButton: {
        marginBottom: 15,
        marginLeft: -15,
        marginRight: 5,
        borderRadius: 4,
        boxShadow: 'none',
        fontSize: 12,
        color: 'gray',
        fontFamily: 'proximaNova-bold',
        textTransform: 'none',
        '&:hover': {
            boxShadow: 'none',
        }
    },
    bottomButtons: {
        marginTop: 30
    },
    centerBottomButtons: {
        marginTop: 30,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -16,
        marginLeft: -15
    },
    buttonWrapper: {
        position: 'relative',
    },
    errorText: {
        marginLeft: 0,
        marginTop: 14,
        fontSize: 13,
        color: 'red'
    },
});
  
export default styles;
  