import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Button, Typography, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList, Divider } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import { AuthenticationContext } from 'repository/stores/authentication-store';

// Children
import { logOut } from 'repository/actions/authentication';

// Styles
import styles from './app-bar-account-styles';
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  let { seamless, dark } = props;
  const [state, dispatch] = useContext(AuthenticationContext);
  const [ open, setOpen ] = React.useState(false);
  const [ anchorEl, setAnchorEl ] = React.useState(null);

  let name = `${state.user.firstName} ${state.user.lastName}`;
  let initials = `${state.user.firstName.charAt(0)} ${state.user.lastName.charAt(0)}`;

  let rootStyle = classes.root;
  if (seamless) {
    rootStyle = classes.rootSeamless;
  }
  let textClass = classes.text;
  let iconClass = classes.icon;
  if (dark) {
    textClass = classes.textDark;
    iconClass = classes.iconDark;
  }

  return (
    <div className={rootStyle}>
        <Button onClick={(event) => { setAnchorEl(event.currentTarget); setOpen(true)}} className={classes.button}>
          <Avatar className={classes.avatar}>{initials}</Avatar>
          <Typography className={textClass}>{state.user.firstName} {state.user.lastName} </Typography>
          <KeyboardArrowDown className={iconClass}/>
        </Button>
        <Popper open={open} anchorEl={anchorEl} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{ transformOrigin: {horizontal: [0,0,0,0]}}}
            >
              <Paper elevation={0} className={classes.menu}>
                <ClickAwayListener onClickAway={(event) => {  setOpen(false)}}>
                  <MenuList className={classes.menuList}>
                    <Typography className={classes.email}>{state.user.email}</Typography>
                    <Divider className={classes.indent}/>
                    <MenuItem onClick={() => logOut(dispatch)} className={classes.menuItemTop}>Logout</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
    </div>
  );
}