import React from 'react';
import { AuthenticationProvider } from 'repository/stores/authentication-store';

export default function (props) {
  return (
    <AuthenticationProvider>
      {props.children}
    </AuthenticationProvider>
  )
}
