import textField from './text-field';
import currencyField from './currency-field';
import appBar from './app-bar';
import button from './button';
import label from './label';
import popup from './popup';
import section from './section';
import multiSelect from './multi-select';
import multiSelectDeep from './multi-select-deep';
import fileUpload from './file-upload';
import geoLocation from './geo-location';
import phoneNumber from './phone-number';
import loader from './loader';
import radio from './radio';
import cascade from './cascade';
import multiRadio from './multi-radio';
import multiField from './multi-field';
import select from './select';

export const BOVTextField = textField;
export const BOVButton = button;
export const BOVPopup = popup;
export const BOVSection = section;
export const BOVMultiSelect = multiSelect;
export const BOVFileUpload = fileUpload;
export const BOVGeoLocation = geoLocation;
export const BOVPhoneNumber = phoneNumber;
export const BOVLoader = loader;
export const BOVAppBar = appBar;
export const BOVLabel = label;
export const BOVCurrencyField = currencyField;
export const BOVRadio = radio;
export const BOVCascade = cascade;
export const BOVMultiRadio = multiRadio;
export const BOVMultiField = multiField;
export const BOVSelect = select;
export const BOVMultiSelectDeep = multiSelectDeep;