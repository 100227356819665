import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper, Select, InputBase, MenuItem, Chip, Checkbox, ListItemText } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import styles from './multi-select-deep-styles';
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const { title, subtitle, placeholder, selects, errorMessage, onChange, values } = props;

  return (
    <div className={classes.root}>
      <Grid container className={classes.grid}>
        <Grid item>
          <Typography className={classes.subtitle}>{title}</Typography>
        </Grid>
      </Grid>
      {subtitle &&
        <Typography className={classes.subtitleItalic}>
          {subtitle}
        </Typography>
      }
      {selects.map((select, indexSelect) => {
        return (
          <div className={classes.toplevelSelects}>
            <Typography className={classes.subtitleLarge}>{select.subQuestionText}</Typography>
            {select.subQuestions.map((item, subItemIndex) => {
              return (
                <div className={classes.selectO}>
                    <Grid container className={classes.grid}>
                      <Grid item>
                        <Typography className={classes.subtitle}>{item.subQuestionText}</Typography>
                      </Grid>
                    </Grid>
                    {item.subQuestionInstruction &&
                      <Typography className={classes.subtitleItalic}>
                        {item.subQuestionInstruction}
                      </Typography>
                    }
                    <Paper className={classes.paper}>
                    <div>
                      {(values[indexSelect]['subQuestions'][subItemIndex]['selectedSubAnswers'].length === 0) &&
                        <Typography className={classes.helperText}>
                          Please select from the list below
                        </Typography>
                      }
                      <Select
                        multiple
                        fullWidth
                        value={values[indexSelect]['subQuestions'][subItemIndex]['selectedSubAnswers']}
                        onChange={(event) => onChange(indexSelect, subItemIndex, event)}
                        input={<InputBase />}
                        classes={{
                          icon: classes.hideInputBase,
                          root: classes.select
                        }}
                        renderValue={selected => (
                          <div className={classes.chips}>
                            {selected.map(value=> (
                              <Chip key={value} label={value} className={classes.chip} />
                            ))}
                          </div>
                        )}
                      >
                        {item.subAnswers.map(subitem => (
                          <MenuItem value={subitem.answerText}>
                            <Checkbox checked={values[indexSelect]['subQuestions'][subItemIndex]['selectedSubAnswers'].indexOf(subitem.answerText) > -1} />
                            <ListItemText primary={subitem.answerText} />
                          </MenuItem>
                        ))}
                      </Select>

                      </div>
                  </Paper>
                  </div>
              )
            })}
          </div>
        )})}
    </div>
  )
}


/*

  

*/