/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography,  Paper, Button, Link} from '@material-ui/core';
import styles from './file-upload-styles';
import clsx from 'clsx';
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const { title, subtitle, placeholder, onResult, URI, errorMessage, className} = props;
 
  let classField = '';

  const _handleImageChange = (e) => {

    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    if(file.size > 5242880){
      alert("File is too big. The maximum size allowed is 5MB.");
    } else {
      reader.onloadend = () => {

        onResult(reader.result);
      }
  
      reader.readAsDataURL(file);
    }
  }

  if (className) {
    classField=className;
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.subtitle}>{title}</Typography>
        {subtitle &&
          <Typography className={classes.subtitleItalic}>
          {subtitle}
          </Typography>
        }
        <Paper className={classes.paper}>
          <input
            accept="image/*"
            className={classes.input}
            id="contained-button-file"
            type="file"
            onChange={_handleImageChange}
          />
          <label htmlFor="contained-button-file">
            {(URI == null) &&
              <Button component="span" variant="outlined" className={classes.uploadPhoto}>
                {placeholder}
              </Button> 
            }
            {(URI != null) &&
              <div>
                <img className={clsx(classes.photoImage, classField)} src={URI} />
                <div />
                <Link
                  className={classes.link}
                  component="span"
                  variant="body2"
                >
                  Change Photo
                </Link>
              </div>
            }
          </label>
        </Paper>
        {(errorMessage )&&
          <Typography className={classes.error}>{errorMessage}</Typography>
        }
    </div>
  );
}