import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, InputBase, Paper,FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

// Styles
import styles from './radio-styles';
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const { title, subtitle, placeholder, options, value, errorMessage, onChange} = props;

  return (
      <div>
        <Typography className={classes.title}>{title}</Typography>
        {(subtitle != null) &&
            <Typography className={classes.subtitle}>
             {subtitle}
            </Typography>
        }
        <Paper className={classes.paper}>
          {props.children}
          <FormControl component="fieldset">
            <RadioGroup value={value} onChange={onChange}>
              {options.map((option, index) => {
                return (
                  <FormControlLabel className={classes.label} value={Number(option.answerIndex)} control={<Radio />} label={option.answerText} />
                )
              })}
            </RadioGroup>
          </FormControl>
        </Paper>
        {(errorMessage )&&
          <Typography className={classes.error}>{errorMessage}</Typography>
        }
      </div>
  );
}