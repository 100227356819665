const assert = require("assert");
const io = require('socket.io-client');
const feathers = require('@feathersjs/client');

let apiClient;

function initClient(callback) {

    const socket = io(process.env.REACT_APP_API_URL,{
        path: '/v1/client/socket.io/'
    });
    apiClient = feathers();
    apiClient.configure(feathers.socketio(socket,{
        timeout: 30000
    }));
    apiClient.configure(feathers.authentication());
    return callback(null, apiClient);
}

function getClient() {
    assert.ok(apiClient, "API has not been initialized. Please call init first.");
    return apiClient;
}

module.exports = {
    initClient,
    getClient
};