const styles = theme => ({
    appBar: {
        position: 'fixed',
        backgroundColor: theme.palette.primary.light,
        paddingTop:3,
        height: 60,
        marginLeft: 270,
        zIndex: 1000,
        [theme.breakpoints.up('sm')]: {
          height: 60,
          zIndex: 1000,
          width: '100%',
        },
        [theme.breakpoints.up('md')]: {
          width: `100%`,
          zIndex: 1000,
          height: 60,
        }
    },
    appBarSeamless: {
      position: 'fixed',
      backgroundColor: theme.palette.primary.light,
      paddingTop:3,
      height: 60,
      marginLeft: 270,
      zIndex: 1000,
      [theme.breakpoints.up('sm')]: {
        height: 60,
        zIndex: 1000,
        width: '100%',
      },
      [theme.breakpoints.up('md')]: {
        zIndex: 1000,
        width: `100%`,
        height: 80,
      }
    },

    appBarTransparent: {
      position: 'absolute',
      backgroundColor: 'rgba(255,255,255,0)',
      paddingTop:20,
      height: 60,
      marginLeft: 270,
      zIndex: 1000,
      [theme.breakpoints.up('sm')]: {
        height: 60,
        zIndex: 1000,
        width: '100%',
      },
      [theme.breakpoints.up('md')]: {
        zIndex: 1000,
        width: `100%`,
        height: 80,
      }
    },
    indentForSimulator: {
      marginRight: 0,
      [theme.breakpoints.up('lg')]: {
        marginRight: 358,
      }
    }
});
  
export default styles;
  