import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, LinearProgress } from '@material-ui/core';
import styles from './loading-styles.js';
const useStyles = makeStyles(styles);

export default function (props) {
    const classes = useStyles();

    let { full, color } = props;

    if (props.full) {
      return (
        <div className={classes.root}>
          <LinearProgress color="primary" className={classes.progressLinear} />
        </div>
      );
    } else {

      if (color === 'light') {
        return (
          <div className={classes.rootSmall}>
            <CircularProgress className={classes.progressSmallLight} />
          </div>
        );
      }
      return (
        <div className={classes.rootSmall}>
          <CircularProgress className={classes.progressSmall} />
        </div>
      );
    }
    
}