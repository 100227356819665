import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, InputBase, Paper,FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

// Styles
import styles from './multi-radio-styles';
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const { title, subtitle, radios, errorMessage, onChange, values} = props;

  return (
      <div>
        <Typography className={classes.title}>{title}</Typography>
        {(subtitle != null) &&
            <Typography className={classes.subtitle}>
             {subtitle}
            </Typography>
        }
        <Paper className={classes.paper}>
          {radios.map((radio, indexRadio) => {
            return (
              <FormControl className={classes.form} component="fieldset">
                <Typography className={classes.radioTitle}>{indexRadio + 1}. {radio.subQuestionText}</Typography>
                <Typography className={classes.radioSubtitle}>
                {radio.subQuestionInstruction}
                </Typography>
                <RadioGroup value={values[indexRadio]['answerIndex']} onChange={(event) => onChange(indexRadio, event)}>
                  {radio.subAnswers.map((option, index) => {
                    return (
                      <FormControlLabel className={classes.label} value={option.subAnswerIndex} control={<Radio />} label={option.answerText} />
                    )
                  })}
                </RadioGroup>
              </FormControl>
            )
          })}
        </Paper>
        {(errorMessage )&&
          <Typography className={classes.error}>{errorMessage}</Typography>
        }
      </div>
  );
}

  /*
  <FormControl component="fieldset">
            <RadioGroup value={value} onChange={onChange}>
              {options.map((option, index) => {
                return (
                  <FormControlLabel className={classes.label} value={index} control={<Radio />} label={option.answerText} />
                )
              })}
            </RadioGroup>
          </FormControl>
  */