import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper, InputBase, Button, Menu, MenuItem, Divider, Icon } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';

// Styles
import styles from './select-styles';
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const { title, subtitle, errorMessage, options, onChange, value} = props;
  const [categoryAnchorEl, setCategoryAnchorEl] = useState(null);
  const [subcategoryAnchorEl, setSubcategoryAnchorEl] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  useEffect(()=> {

    let ans = [];
    for (const [index, option] of options.entries()) {
      ans.push( {
        "answerText": option.answerText,
        "answerValue": index
      });
    }

    setAnswers(ans);

  },[]);

  return (
      <div className={classes.root}>
        <Typography className={classes.title}>{title}</Typography>
        {(subtitle != null) &&
            <Typography className={classes.subtitle}>
             {subtitle}
            </Typography>
        }
        <Grid container className={classes.grid}>
        <Grid item>
          <Typography className={classes.subtitle}>Select here</Typography>
        </Grid>
        <Grid item>
          <KeyboardArrowDown className={classes.icon}/>
        </Grid>
      </Grid>
      {(answers.length > 0) &&
        <Grid container>
          <Grid item className={classes.item2}>
              <Paper className={classes.paper}>
                {(value === null) &&
                  <Typography className={classes.helperText}>
                    Click here to select an option
                  </Typography>
                }
                {(value != null) &&
                  <Typography className={classes.subcategoryTitle}>
                    {options[value].answerText}
                  </Typography>
                }
                <Button className={classes.button} onClick={(event)=> setCategoryAnchorEl(event.currentTarget)} />
              </Paper>
          </Grid>
          <Grid item className={classes.spacer}>
          </Grid>
        </Grid>
      }
      
      <Menu
        id="category-menu"
        anchorEl={categoryAnchorEl}
        value={value}
        keepMounted
        open={Boolean(categoryAnchorEl)}
        onClose={(event) =>  setCategoryAnchorEl(null)}
        className={classes.menuHeight}
      >
        {answers.map((item, index) =>  {
          return (
           <div>
              <MenuItem value={item.answerIndex} className={classes.menuRootCategories} onClick={(event) => {onChange(index); setCategoryAnchorEl(null);}}>
                <Grid container className={classes.gridPaperSub}>
                  <Grid item>
                    <Typography className={classes.menuTitleSub}>
                      {item.answerText}
                    </Typography>
                  </Grid>
                </Grid>
              </MenuItem>
              {(index < answers.length -1) &&
                <Divider className={classes.dividerSubcategories}/>
              }
           </div>
          )
        })}
      </Menu>
     
        {(errorMessage )&&
          <Typography className={classes.error}>{errorMessage}</Typography>
        }
      </div>
  );
}