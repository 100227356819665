const styles = theme => ({
      title: {
        padding: 0,
        marginTop: 0,
        marginBottom: 4,
        paddingTop: 0,
        fontFamily: 'proximaNova-bold',
        fontSize: 14
      },
      subtitle: {
        marginTop: 14,
        marginBottom: 18,
        marginRight: '30%',
        fontSize: 13,
        fontFamily: 'proximaNova-italic',
        lineHeight: 1.25,
        color: 'rgba(134,134,134,1)',
        width: '100%'
      },
      descriptionField: {
        fontSize: 15,
        fontFamily: 'proximaNova-regular',
      },
      paper: {
        width: 'auto',
        height: 'auto',
        maxWidth: 624,
        paddingTop: 14,
        paddingBottom: 14,
        paddingLeft: 24,
        paddingRight: 24,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
        boxShadow: '0 1px 3px 0px rgba(180, 180, 180, .4)'
      },
      error: {
        fontSize: 13,
        color: 'red',
        marginLeft: 5,
        marginTop: 4
      }
});
  
export default styles;
  